.container.testimonials__container{
    width:40%;
    padding-bottom: 4rem;
}

.client__avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow:hidden;
    position: relative;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.testimonial{
    background: var(--color-bg-variant);;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review{
    color:var(--color-light);
    font-weight:300;
    display:block;
    width:80%;
    margin:0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet{
    background: var(--swiper-pagination-bullet-active-color,--color-primary);
    background: var(--swiper-pagination-bullet-inactive-color,rgb(151, 20, 20));
    
}
.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color,rgb(151, 20, 20));
    
}
/***************************************MEDIA QUERIES (MEDIUM DEVICES)  ***************************************** */
@media screen and (max-width:1024px){

    .container.testimonials__container{
        width:60%;
    }
    }
    /***************************************MEDIA QUERIES (MEDIUM DEVICES)  ***************************************** */
    
    @media screen and (max-width:600px){
    .container.testimonials__container{
        width: var(--container-width-sm);
    }


    .client__review{
        width: var(--container-width-sm);
    }
}