.experience__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 2rem;
    margin-bottom: 2rem;
    max-width: 1200px; /* Optional: to prevent the layout from being too wide */
    margin: 0 auto; /* Centers the container horizontally */
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    margin-bottom: 2rem;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    width: 100%;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

/* Media Query for Smaller Screens */
@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr; /* Stacks the items vertically on smaller screens */
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}
